@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&family=Sassy+Frass&family=Varela&family=Varela+Round&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

button {
    transition: all .5s ease;
}

/* html {
    scroll-behavior: smooth;
  } */